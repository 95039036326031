import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import S3Image from '../components/S3Image'
import { faPause, faPlay, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

class ArtistModal extends Component {
  render () {
    let a = this.props.data && this.props.data.selectedArtist ? this.props.data.selectedArtist : null
    const { showModal } = this.props.data
    const { dispatch } = this.props
    let song = null
    let showPause = false

    if (a) {
      song = a.songs[0]
      if (song && song === this.props.data.selectedSong && this.props.data.songPlaying) {
        showPause = true
      }
    }
    if (showModal) {
      return (
        <div className='fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-75 flex py-12'>
          {a &&
          <div className='bg-white rounded-custom w-auto h-auto shadow flex lg:flex-row flex-col m-auto align-top justify-end lg:w-3/6 w-5/6 relative '>
            <div className='lg:w-6/12 w-full '>
              <S3Image className={`w-full rounded-custom-top`} width={500} height={500} fit={'cover'} image={a.acf.image} />
              <div className='bg-gray-900 p-8 rounded-customer-bl'>
                <button onClick={() => { dispatch({ type: 'SET_SONG', 'data': song, 'songPlaying': !showPause }) }} className='focus:outline-none bg-white rounded w-full overflow-hidden flex flex-row items-center justify-start'>
                  <div className='w-12 h-12 bg-orange-900 flex items-center justify-center text-white'>
                    {!showPause &&
                    <FontAwesomeIcon icon={faPlay} />
                    }
                    {showPause &&
                    <FontAwesomeIcon icon={faPause} />
                    }
                  </div>
                  <div className='pl-2 justify-start w-full'>
                    <div className='text-sm truncate w-10/12 text-left font-semibold'>{a['post_title']}</div>
                    <div className='text-sm truncate w-10/12 text-left text-gray-1200 font-medium overflow-hidden'>{song && song.post_title}</div>
                  </div>
                </button>
                <div className='mt-1'>
                  <Link onClick={() => { dispatch({ type: 'TOGGLE_MODAL', data: false }) }} to={`/profile/${a['post_name']}`} className='focus:outline-none text-orange-900 underline text-xs'>View all music</Link>
                </div>
              </div>
            </div>
            <div className='lg:w-6/12 w-full p-8'>
              <h1 className='text-xl font-semibold'>{a['post_title']}</h1>
              <p className='text-xs mb-4'>{a['acf']['type'].map((t, i) => (
                <span key={`term${i}`}>{t.name} {i < (a['acf']['type'].length - 1) ? ' | ' : ''}</span>
              ))}</p>
              <div
                className='h-64 overflow-hidden after-fade text-sm p-margin-bottom'
                dangerouslySetInnerHTML={{
                  __html: a.acf.description
                }} />
              <p className=' text-right'>
                <Link onClick={() => { dispatch({ type: 'TOGGLE_MODAL', data: false }) }} to={`/profile/${a['post_name']}`} className='focus:outline-none text-orange-900 font-semibold mt-4'>Read more</Link>
              </p>
            </div>
            <button onClick={() => { dispatch({ type: 'TOGGLE_MODAL', data: false }) }} className='focus:outline-none absolute text-white text-2xl close-button'>
              <FontAwesomeIcon icon={faTimes} />
            </button>

          </div>
          }
        </div>

      )
    }
    return null
  }
}

const mapStateToProps = ({ data }) => ({ data })

const mapDispatchToProps = (dispatch) => bindActionCreators({ dispatch: o => o }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistModal)
)
