import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Nav from '../../components/Nav'
import ArtistTapestryHome from '../../components/ArtistTapestryHome'
// import ArtistGrid from '../../components/ArtistGrid'
// import ArtistModal from '../../components/ArtistModal'
import compressed from '../../video/no-audio.mp4'
import { Link } from 'react-router-dom'
import icon1 from '../../img/icon1.png'
import icon2 from '../../img/icon2.png'
import icon3 from '../../img/icon3.png'
import footerWaves from '../../img/footer-waves.png'
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from 'react-helmet'
import poster from '../../img/black.png'

class Home extends Component {
  render () {
    let pageData = null
    if (this.props.data && this.props.data.pages) {
      pageData = this.props.data.pages.find(p => p.slug === 'home')
    }

    const icons = [icon1, icon2, icon3]
    const yoastHead = pageData ? pageData.yoast_head : null

    const getField = (name) => {
      if (pageData) {
        return pageData.acf[name]
      }

      return null
    }

    return (
      <div>
        <Helmet>
          {ReactHtmlParser(yoastHead)}
        </Helmet>
        <div className='h-screen bg-gradient-to-b from-irish-50 to-black relative'>
          <div className='relative container mx-auto h-full flex flex-col pt-8 z-10'>
            <Nav />
            <div className='flex-1 flex flex-col items-center justify-center pb-24'>
              <h2 className='text-white text-xl lg:text-5xl font-thin'>{getField('welcome')}</h2>

              <h1 className='text-white text-2xl lg:text-7xl uppercase font-black'>{getField('title') ? getField('title').split(' ')[0] : null}<span className='font-normal'>{getField('title') ? getField('title').split(' ')[1] : ''}</span></h1>
              <p className='text-white text-center text-xl lg:text-3xl font-thin mt-6'>{getField('intro')}</p>

            </div>

          </div>
          <div className='w-full absolute z-0 inset-0 text-center mx-auto h-full overflow-hidden'>
            <video poster={poster} className='mx-auto w-full h-full object-cover opacity-50' autoPlay playsInline loop muted src={compressed} />
          </div>
        </div>
        <div className='h-screen flex flex-col justify-center bg-gradient-to-b from-black to-irish-100 relative'>
          <ArtistTapestryHome pageData={pageData} />
          <div className='w-full absolute z-0 inset-0 text-center mx-auto h-full overflow-hidden bg-black opacity-50' />
        </div>

        <div className='flex flex-col justify-center bg-gradient-to-b from-irish-70 to-gray-1400 relative'>
          <div className='container flex flex-row flex-wrap mx-auto z-10 lg:w-8/12 w-full'>

            {getField('spotlights') && getField('spotlights').map((s, i) => (
              <div className={`w-full lg:w-1/${getField('spotlights').length} text-white p-4 pt-0 text-center`}>
                <div className='bg-gray-1300 rounded-lg p-6 pb-16 pt-12 text-center'>
                  <img className='inline-block mb-6 w-24 h-24' src={icons[i]} alt='' />
                  <h2 className='text-white text-2xl font-black uppercase'>{s.title.split(' ')[0]}<span className='font-normal'>{s.title.split(' ')[1]}</span></h2>
                  <p className='text-white mt-5 font-light text-sm h-10'>{s.intro}</p>
                  <Link className='text-white uppercase bg-orange-700 p-4 inline-block mt-16' to={s.link.url}>
                    Visit <span className='font-black'>{s.link.title}</span>
                  </Link>
                </div>
              </div>

            ))}

          </div>
        </div>
        <div className='relative h-32 bg-gray-1400'>
          <img src={footerWaves} className='footer-waves' alt='' />
        </div>
        {/* <div className='bg-gray-1000'>
          <div className='container mx-auto pt-32'>
            <div className='flex items-center pb-2 mb-8 border-b border-gray-400'>
              <div className='w-1/3 '>
                <h1 className='text-6xl uppercase'>The<span className='font-black'>Makers</span></h1>

              </div>
              <div className='w-1/3 ' />

              <div className='w-1/3 text-right'>
                <input className='bg-gray-1100 font-light italic appearance-none w-full py-4 px-3 text-black leading-tight focus:outline-none focus:shadow-outline' id='search' type='text' placeholder='Search artists' />

              </div>

            </div>
            <ArtistGrid filter={''} artists={this.props.data.artists} />
            <ArtistModal />
          </div>
        </div> */}
      </div >

    )
  }
};

const mapStateToProps = ({ data }) => ({
  data
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {

    },
    dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Home))
