import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Nav from '../../components/Nav'
import ArtistTapestry from '../../components/ArtistTapestry'
import ArtistGrid from '../../components/ArtistGrid'
import ArtistModal from '../../components/ArtistModal'
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from 'react-helmet'

class Voices extends Component {
  constructor (props) {
    super(props)
    this.state = {
      filter: ''
    }
  }

  render () {
    let pageData = null
    if (this.props.data && this.props.data.pages) {
      pageData = this.props.data.pages.find(p => p.slug === 'sentric-voices')
    }

    const yoastHead = pageData ? pageData.yoast_head : null

    const getField = (name) => {
      if (pageData) {
        return pageData.acf[name]
      }

      return null
    }

    return (
      <div>
        <Helmet>
          {ReactHtmlParser(yoastHead)}
        </Helmet>
        <div className='min-h-screen h-screen bg-gradient-to-b from-irish-50 to-black relative'>
          <div className='relative container mx-auto h-full flex flex-col pt-8 z-10'>
            <Nav />
          </div>

          <div className='w-full absolute z-0 inset-0 text-center mx-auto h-full overflow-hidden'>
            <ArtistTapestry title='Sentric Voices' />
          </div>

          <div className='w-full absolute z-5 inset-0 text-center mx-auto h-full overflow-hidden flex flex-col justify-end items-center pb-8'>
            <p className='text-white text-center text-xl font-normal' >{getField('intro1')}</p>
            <p className='text-white text-center text-base font-thin mt-6'>{getField('intro2')}</p>
          </div>
        </div>

        <div className='bg-gray-1000'>
          <div className='container mx-auto pt-8 pl-2 pr-2'>
            <div className='flex items-center pb-2 mb-8 border-b border-gray-400'>
              <div className='lg:w-1/3 w-1/2'>
                <h1 className='lg:text-5xl text-xl uppercase'>The<span className='font-black'>Voices</span></h1>

              </div>
              <div className='w-1/3 hidden lg:block' />

              <div className='lg:w-1/3 w-1/2 text-right'>
                <input onChange={(e) => this.setState({ 'filter': e.target.value })} className='bg-gray-1100 font-light italic appearance-none w-full py-4 px-3 text-black leading-tight focus:outline-none focus:shadow-outline' id='search' type='text' placeholder='Search artists' />

              </div>

            </div>
            <ArtistGrid type='voice' filter={this.state.filter} artists={this.props.data.artists} />
            <ArtistModal />
          </div>
        </div>
      </div >

    )
  }
};

const mapStateToProps = ({ data }) => ({
  data
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {

    },
    dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Voices))
