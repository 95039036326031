import dotenv from 'dotenv'
import 'babel-polyfill'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/App'
import './assets/custom.scss'
import './assets/main.css'

import ScrollToTop from './components/scroll'
dotenv.config()

const target = document.querySelector('#root')

render(
  <Provider store={store}>
    <ConnectedRouter history={history} >
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>,
  target
)
