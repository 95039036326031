// import { push } from 'connected-react-router'
import fetch from 'isomorphic-fetch'
// import { toastPush } from '../helpers/Toast'
// import store from '../store'

const url = 'https://dfxv949kbt0ud.cloudfront.net/'

export const getArtists = () => (dispatch) => {
  console.log('getting artists')
  fetch(url + 'wp-json/wp/v2/artistsWithSongs', {
    'method': 'GET'

  })
    .then(response => response.json())
    .then(response => {
      dispatch({ type: 'GET_ARTISTS', data: response })
    })
    .catch(err => {
      console.log(err)
    })
}

export const getPages = () => (dispatch) => {
  console.log('getting pages')
  fetch(url + 'wp-json/wp/v2/pages', {
    'method': 'GET'

  })
    .then(response => response.json())
    .then(response => {
      dispatch({ type: 'GET_PAGES', data: response })
    })
    .catch(err => {
      console.log(err)
    })
}
