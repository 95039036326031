import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
const btoa = require('btoa')

class S3Image extends Component {
  render () {
    const { className, image, width, height, fit } = this.props

    if (!image) {
      return null
    }

    // view options
    // https://d1un075m0rjqo1.cloudfront.net/index.html
    // fit = contain
    const endpoint = 'https://d7mtvielewd1r.cloudfront.net'
    let object =
    {
      'bucket': 'sentric-wordpress-assets',
      'key': image.replace('https://s3.sentricdev.co.uk/', ''),
      'edits': {
        'resize': {
          'width': width,
          'height': height,
          'fit': fit
        }
      }
    }

    let objectLowRes =
    {
      'bucket': 'sentric-wordpress-assets',
      'key': image.replace('https://s3.sentricdev.co.uk/', ''),
      'edits': {
        'resize': {
          'width': 50,
          'height': 50,
          'fit': fit
        }
      }
    }

    const url = `${endpoint}/${btoa(JSON.stringify(object))}`
    const lowResUrl = `${endpoint}/${btoa(JSON.stringify(objectLowRes))}`

    return (

      <img style={{ backgroundImage: `url(${lowResUrl})`, backgroundSize: 'cover' }} width={width} height={width} className={className} src={url} title='' />

    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(S3Image)
)
