import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import S3Image from '../components/S3Image'
import compressed from '../video/no-audio.mp4'
import poster from '../img/black.png'

class ArtistTapestry extends Component {
  shuffle (array) {
    var currentIndex = array.length; var temporaryValue; var randomIndex

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  render () {
    const { artists } = this.props.data
    let newArtists = artists ? this.shuffle(artists.slice()) : null
    newArtists = newArtists ? newArtists.filter(a => a.post_status === 'publish') : null

    const title = this.props.title || 'Makers Hub'
    const margins = [8, 4, 6, 0, 0, 12, 12]
    const opacity = [50, 50, 50, 50, 1]
    return (
      <div className='w-full flex flex-col content-center flex-wrap justify-center tapestry overflow-hidden'>
        {newArtists && newArtists.map((a) => (
          <div className={`${'my-' + margins[Math.floor(Math.random() * margins.length)]} ${'opacity-' + opacity[Math.floor(Math.random() * opacity.length)]}`} key={a.ID} >
            <S3Image className={`w-32 h-32`} width={260} height={260} fit={'cover'} image={a.acf.image} />
          </div>
        ))}
        <div className='w-full absolute z-10 text-center mx-auto h-full overflow-hidden'>
          <video poster={poster} className='mx-auto w-full h-full object-cover opacity-50' autoPlay playsInline loop muted src={compressed} />
        </div>

        <div className='absolute z-20 flex items-center justify-center w-full h-full'>
          <h1 className='text-white text-2xl lg:text-5xl uppercase font-black'>{title.split(' ')[0]}<span className='font-normal'>{title.split(' ')[1]}</span></h1>

        </div>

      </div>
    )
  }
}

const mapStateToProps = ({ data }) => ({ data })

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistTapestry)
)
