import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import logo from '../img/logo.png'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

class Nav extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menu: false
    }
  }

  render () {
    const { location } = this.props
    const { pathname } = location

    return (

      <div className='flex flex-row items-center justify-between w-full lg:h-24 pl-4 pr-4'>
        <Link className='w-6/12 lg:w-4/12 ' to='/'>
          <img className='w-full' src={logo} alt='Makers Logo' />
        </Link>
        <div className='hidden lg:block'>
          <ul className='text-white font-semibold flex flex-row uppercase'>
            {cookies.get('makers-hub') === 'true' &&
              <li className={`mr-16 border-b-4 ${pathname.includes('makers-hub') ? 'border-red-800' : 'border-transparent'} hover:border-red-800`}>
                <Link to='/makers-hub'>Makers Hub</Link>
              </li>
            }
            {/* <li className={`mr-16 border-b-4 ${pathname.includes('/catalogue-spotlight') ? 'border-red-800' : 'border-transparent'} hover:border-red-800`}>
              <Link to='/catalogue-spotlight'>Catalogue Spotlight</Link>
            </li> */}
            <li className={`mr-16 border-b-4 ${pathname.includes('sentric-voices') ? 'border-red-800' : 'border-transparent'} hover:border-red-800`}>
              <Link to='/sentric-voices'>Sentric Voices</Link>
            </li>
            {/* <li className={`border-b-4 ${pathname.includes('bust-bank') ? 'border-red-800' : 'border-transparent'} hover:border-red-800`}>
              <Link to='/bust-bank'>B.UST Bank</Link>
            </li> */}
          </ul>
        </div>
        <div className='lg:hidden'>
          <button onClick={() => { this.setState({ menu: !this.state.menu }) }} className='focus:outline-none flex flex-row items-center p-2 text-white' >
            <svg viewBox='0 0 100 80' width='20' height='20'>
              <rect fill='#ffffff' width='100' height='10' />
              <rect fill='#ffffff' y='30' width='100' height='10' />
              <rect fill='#ffffff' y='60' width='100' height='10' />
            </svg>
          </button>
        </div>
        {this.state.menu &&
          <div className='absolute bg-black inset-0 z-100 h-screen'>
            <div className='pt-8 flex items-end justify-end mr-4'>
              <button onClick={() => { this.setState({ menu: !this.state.menu }) }} className='focus:outline-none flex flex-row items-center p-2 text-white' >
                <svg viewBox='0 0 100 80' width='20' height='20'>
                  <rect fill='#ffffff' width='100' height='10' />
                  <rect fill='#ffffff' y='30' width='100' height='10' />
                  <rect fill='#ffffff' y='60' width='100' height='10' />
                </svg>
              </button>
            </div>
            <div>
              <ul className='text-white font-semibold flex flex-col uppercase justify-center items-center'>
                <li className={`border-b-4   mb-4 ${pathname === ('/') ? 'border-red-800' : 'border-transparent'} hover:border-red-800`}>
                  <Link to='/'>Home</Link>
                </li>
                <li className={`border-b-4   mb-4 ${pathname.includes('makers-hub') ? 'border-red-800' : 'border-transparent'} hover:border-red-800`}>
                  <Link to='/makers-hub'>Makers Hub</Link>
                </li>
                {/* <li className={`mr-16 border-b-4 ${pathname.includes('/catalogue-spotlight') ? 'border-red-800' : 'border-transparent'} hover:border-red-800`}>
              <Link to='/catalogue-spotlight'>Catalogue Spotlight</Link>
            </li> */}
                <li className={`border-b-4  mb-4 ${pathname.includes('sentric-voices') ? 'border-red-800' : 'border-transparent'} hover:border-red-800`}>
                  <Link to='/sentric-voices'>Sentric Voices</Link>
                </li>
                {/* <li className={`border-b-4 ${pathname.includes('bust-bank') ? 'border-red-800' : 'border-transparent'} hover:border-red-800`}>
              <Link to='/bust-bank'>B.UST Bank</Link>
            </li> */}
              </ul>
            </div>
          </div>
        }
      </div>

    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Nav)
)
