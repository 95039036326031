import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import ArtistTile from '../components/ArtistTile'

class ArtistGrid extends Component {
  render () {
    const { artists, filter, type } = this.props
    let newArtists = artists ? artists.slice() : null

    if (newArtists) {
      // filter my type maker or voice
      newArtists = newArtists.filter(a => a.acf.artistType ? a.acf.artistType.includes(type) : false)

      console.log(newArtists)
      newArtists.sort((a, b) => {
        return a.post_title.localeCompare(b.post_title)
      })

      // remove private artists
      newArtists = newArtists.filter(a => a.post_status === 'publish')

      if (filter) {
        newArtists = newArtists.filter(a => a.post_title.toLowerCase().includes(filter.toLowerCase()))
      }
    }

    if (newArtists && newArtists.length) {
      return (
        <div className='flex flex-row flex-wrap relative'>
          { newArtists.map(a => (
            <ArtistTile artist={a} key={`at${a.ID}`} />
          ))}
        </div>
      )
    }
    return null
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistGrid)
)
