import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import S3Image from '../components/S3Image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

import { faPause, faPlay, faVolumeUp } from '@fortawesome/free-solid-svg-icons'
const moment = require('moment')
const momentDurationFormatSetup = require('moment-duration-format')
momentDurationFormatSetup(moment)

// import S3Image from '../components/S3Image'
class MP3Player extends Component {
  constructor (props) {
    super(props)
    this.audio = React.createRef()
    this.state = {
      duration: null,
      currentTime: 0,
      playing: false,
      volume: 100
    }
    this.handlePlay = this.handlePlay.bind(this)
    this.handleStop = this.handleStop.bind(this)
  };

  handlePlay () {
    if (this.audio.current.src.includes('.mp3')) {
      this.audio.current.play()
      clearInterval(this.currentTimeInterval)

      this.setState({ playing: true })
      this.props.dispatch({ type: 'SONG_PLAYING', 'data': true })
    }
  }

  handleStop () {
    // this.audio.current.currentTime = 0
    // this.slider.value = 0
    this.setState({ playing: false })
    clearInterval(this.currentTimeInterval)
    this.props.dispatch({ type: 'SONG_PLAYING', 'data': false })

    this.audio.current.pause()
  }

  componentDidMount () {
    this.slider.value = 0
    this.currentTimeInterval = null

    // Get duration of the song and set it as max slider value
    this.audio.current.onloadedmetadata = function () {
      this.setState({ duration: this.audio.current.duration })
    }.bind(this)

    // Sync slider position with song current time
    this.audio.current.onplay = () => {
      this.currentTimeInterval = setInterval(() => {
        this.slider.value = this.audio.current.currentTime
        this.setState({ currentTime: this.audio.current.currentTime })
      }, 200)
      this.volume.value = this.audio.current.volume * 100
    }

    this.audio.current.onpause = () => {
      clearInterval(this.currentTimeInterval)
    }

    // Seek functionality
    this.slider.onmousedown = (e) => {
      clearInterval(this.currentTimeInterval)
    }

    this.slider.onmouseup = (e) => {
      this.currentTimeInterval = setInterval(() => {
        this.slider.value = this.audio.current.currentTime
        this.setState({ currentTime: this.audio.current.currentTime })
      }, 200)
      this.audio.current.currentTime = e.target.value
    }

    this.volume.onmouseup = (e) => {
      this.audio.current.volume = e.target.value / 100
    }
  }

  componentDidUpdate (prevProps) {
    if ((prevProps.data.songPlaying !== this.props.data.songPlaying) ||
    (prevProps.data.selectedSong !== this.props.data.selectedSong)
    ) {
      if (this.props.data.songPlaying) {
        this.handlePlay()
      }
      if (!this.props.data.songPlaying) {
        this.handleStop()
      }
    }
  }

  render () {
    const selectedSong = this.props.data && this.props.data.selectedSong ? this.props.data.selectedSong : null
    const name = selectedSong ? selectedSong.post_title : ''
    const artist = selectedSong ? selectedSong.acf.artist.post_title : ''
    const artistSlug = selectedSong ? selectedSong.acf.artist.post_name : ''
    const src = selectedSong ? selectedSong.acf.mp3.url : ''
    const image = selectedSong ? selectedSong.acf.image : null
    return (
      <div className='w-full h-auto lg:h-24 bg-white flex flex-row justify-between items-center pl-6 pr-6 flex-wrap'>

        <Link to={`/profile/${artistSlug}`} className='focus:outline-none flex flex-row items-center justify-start lg:w-1/4 w-full mt-4 lg:mt-0'>
          <div className='w-16 h-16 flex items-center justify-center text-white'>
            {image &&
            <S3Image className={`w-full`} width={200} height={200} fit={'cover'} image={image} />
            }
          </div>
          <div className='pl-6'>
            <div className='text-sm font-semibold'>{artist}</div>
            <div className='text-sm text-gray-1200 font-medium'>{name}</div>
          </div>
        </Link>
        <audio ref={this.audio} src={src} />

        <div className='flex flex-row items-center justify-between lg:w-2/4 w-full lg:pl-8 lg:pr-8 mt-4 lg:mt-0'>
          <div className='w-12 h-12  rounded-full bg-orange-900 flex items-center justify-center text-white mr-1 lg:mr-4'>
            {!this.state.playing &&
            <button className='focus:outline-none' type='button' onClick={this.handlePlay}>

              <FontAwesomeIcon icon={faPlay} />
            </button>
            }
            {this.state.playing &&
            <button className='focus:outline-none' onClick={this.handleStop}>
              <FontAwesomeIcon icon={faPause} />
            </button>
            }
          </div>
          <div className='text-sm pr-2 text-gray-500'>{moment.duration(this.state.currentTime, 'seconds').format('mm:ss', { trim: false })}</div>
          <input ref={(slider) => { this.slider = slider }}
            type='range'
            name='points'
            min='0' max={this.state.duration} />
          <div className='text-sm pl-2 text-gray-500'>{moment.duration(this.state.duration, 'seconds').format('mm:ss', { trim: false })}</div>
        </div>
        <div className='invisible lg:visible w-1/4 pr-6 flex flex-row items-end'>
          <div className='flex w-2/4 pr-4 justify-end'>
            <FontAwesomeIcon className='text-gray-500' icon={faVolumeUp} />
          </div>
          <div className='w-2/4 flex justify-start'>
            <input ref={(volume) => { this.volume = volume }}
              type='range'
              min='0' max='100' />
          </div>
        </div>
      </div>

    )
  }
}

const mapStateToProps = ({ data }) => ({ data })

const mapDispatchToProps = (dispatch) => bindActionCreators({ dispatch: o => o }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MP3Player)
)
