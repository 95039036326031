import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import MP3Player from '../components/MP3Player'
import footerLogo from '../img/footer-logo.png'
class Layout extends Component {
  render () {
    return (
      <div className={`${this.props.data && this.props.data.selectedSong ? 'pb-24' : ''}`}>
        {this.props.children}
        <div className={`${this.props.data && this.props.data.selectedSong ? 'fixed bottom-0 w-full z-40 top-shadow' : 'top-shadow hidden'}`}>
          <MP3Player />
        </div>
        <div className='bg-black'>
          <div className='mx-auto container flex lg:flex-row flex-col h-24 items-center lg:justify-between justify-center'>
            <img className='lg:w-32 w-32 pb-4 lg:pb-0' src={footerLogo} alt='' />
            <p className='uppercase font-bold text-gray-1500 text-xs lg:text-sm'>&copy; Sentric Music Publishing</p>
          </div>
        </div>
      </div>

    )
  }
}

const mapStateToProps = ({ data }) => ({
  data
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout))
