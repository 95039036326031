import React, { Component } from 'react'
import Routes from '../routes/routes'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getArtists, getPages } from '../actions/actions'
import { bindActionCreators } from 'redux'

class App extends Component {
  componentDidMount () {
    if (!this.props.data.artists) {
      console.log('getting artists')
      this.props.getArtists()
      this.props.getPages()
    }
  }

  render () {
    return (
      <>
        <Routes location={this.props.location} />
      </>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getArtists,
      getPages
    },
    dispatch)

const mapStateToProps = ({ data }) => ({
  data

})
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App))
