import React from 'react'
import { Switch } from 'react-router-dom'
import { Route } from 'react-router'
import Layout from '../containers/Layout'
import Home from '../containers/Home/Home'
import MakersHub from '../containers/MakersHub/MakersHub'
import Voices from '../containers/Voices/Voices'
import Profile from '../containers/Profile/Profile'

const Routes = (props) => (

  <Switch location={props.location}>
    <Route exact path='/' render={(props) => <Layout location={props.location}><Home /></Layout>} />
    <Route exact path='/makers-hub' render={(props) => <Layout location={props.location}><MakersHub /></Layout>} />
    <Route exact path='/sentric-voices' render={(props) => <Layout location={props.location}><Voices /></Layout>} />
    <Route exact path='/profile/:slug' render={(props) => <Layout location={props.location}><Profile /></Layout>} />

  </Switch>

)

export default Routes
