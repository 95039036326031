import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import S3Image from '../components/S3Image'

class ArtistTile extends Component {
  render () {
    let a = this.props.artist
    const { dispatch } = this.props

    return (
      <button className='sm:w-full md:w-6/12 lg:w-3/12 px-1' onClick={() => { dispatch({ type: 'TOGGLE_MODAL', data: true, artist: a }) }}>
        <div className='duration-200 transition-colors artist-tile  rounded-custom bg-tile mb-4 hover:bg-orange-700 overflow-hidden relative'>
          <div className='imageBox overflow-hidden duration-200'>
            <S3Image className={`w-full`} width={500} height={500} fit={'cover'} image={a.acf.image} />

          </div>
          <div className='w-full p-5 absolute text-white artist-tile__details text-center bottom-0'>
            <h1 className='text-xl font-semibold mb-4'>{a['post_title']}</h1>
            <p className='text-xs text-gray-200 mb-4'>{a['acf']['type'] && a['acf']['type'].map((t, i) => (
              <span key={`term${i}`}>{t.name} {i < (a['acf']['type'].length - 1) ? ' | ' : ''}</span>
            ))}</p>

            {/* <p className='text-xs text-gray-200 '>Tracks: <strong>{String(a['songs'].length).padStart(2, '0')}</strong></p> */}
          </div>
        </div>
      </button>

    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => bindActionCreators({ dispatch: o => o }, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistTile)
)
