import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import S3Image from '../components/S3Image'
// import { Link } from 'react-router-dom'

class ArtistTapestry extends Component {
  shuffle (array) {
    var currentIndex = array.length; var temporaryValue; var randomIndex

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  render () {
    const { artists } = this.props.data
    let newArtists = artists ? this.shuffle(artists.slice()) : null
    newArtists = newArtists ? newArtists.filter(a => a.post_status === 'publish') : null

    const margins = [8, 4, 6, 0, 0, 12, 12]
    const opacity = [50, 50, 50, 50, 1]

    const pageData = this.props.pageData

    const getField = (name) => {
      if (pageData) {
        return pageData.acf[name]
      }

      return null
    }

    return (
      <div className='w-full flex flex-col content-center flex-wrap justify-center tapestry overflow-hidden'>
        {newArtists && newArtists.map((a) => (
          <div className={`${'my-' + margins[Math.floor(Math.random() * margins.length)]} ${'opacity-' + opacity[Math.floor(Math.random() * opacity.length)]}`} key={a.ID} >
            <S3Image className={`w-32 h-32`} width={260} height={260} fit={'cover'} image={a.acf.image} />
          </div>
        ))}
        <div className='absolute z-20 flex flex-col items-center justify-center w-full h-full'>
          <div className='container text-center lg:pl-64 lg:pr-64'>
            <h1 className='text-white text-2xl lg:text-5xl uppercase font-black'>{getField('box_title') ? getField('box_title').split(' ')[0] : null}<span className='font-normal'>{getField('box_title') ? getField('box_title').split(' ')[1] : ''}</span></h1>
            {/* <p className='text-white text-xl font-light'>{getField('box_intro')}</p> */}
            <h1 className='text-white text-2xl lg:text-5xl uppercase font-normal'>{getField('box_intro') ? getField('box_intro').split(' ')[0] : null}<span className='font-black'>{getField('box_intro') ? getField('box_intro').split(' ')[1] : ''}</span></h1>

            {/* <Link className='text-white uppercase bg-orange-700 p-4 inline-block mt-8' to='/makers-hub'>
              Visit <span className='font-black'>Makers hub</span>
            </Link> */}
          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = ({ data }) => ({ data })

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistTapestry)
)
