import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Nav from '../../components/Nav'
import S3Image from '../../components/S3Image'
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'react-helmet'

class Profile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showAllSongs: false,
      album: null
    }
  }
  render () {
    console.log(this.props.match.params.slug)
    const dispatch = this.props.dispatch
    let a = null
    let album = null
    if (this.props && this.props.data && this.props.data.artists && this.props.match.params.slug) {
      a = this.props.data.artists.find(b => b.post_name === this.props.match.params.slug)

      if (this.state.album) {
        // eslint-disable-next-line no-undef
        album = a.albums.find(c => c.ID === this.state.album)
        console.log(album)
      }
    }

    return (
      <div>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{a ? a['post_title'] : ''} - Sentric Music</title>
        </Helmet>

        <div className='lg:h-40 h-20 bg-gradient-to-br from-irish-100 to-black relative z-10'>
          <div className='container mx-auto h-full flex flex-col pt-8 relative z-10'>
            <Nav />
          </div>
        </div>

        <div className='bg-gray-1000'>
          <div className='bg-gray-1000 container mx-auto pl-8 pr-8 lg:pt-16 lg:pb-16 pt-8 pb-8'>
            {a &&
              <div>
                <div className='bg-gray-1000 flex lg:flex-row flex-col m-auto align-top justify-end w-full relative mb-4 lg:mb-12'>
                  <div className='lg:w-6/12 w-full lg:pr-8'>
                    <S3Image className={`w-full`} width={1200} height={1200} fit={'cover'} image={a.acf.image} />
                  </div>
                  <div className='lg:w-6/12 w-full lg:pl-8'>
                    <h1 className='text-2xl lg:text-4xl font-semibold'>{a['post_title']}</h1>
                    <p className='mb-4'>{a['acf']['type'].map((t, i) => (
                      <span className='text-sm font-medium bg-gray-1100 p-1 mr-2 rounded' key={`term${i}`}>{t.name} {i < (a['acf']['type'].length - 1) ? '' : ''}</span>
                    ))}</p>
                    <div
                      className='text-sm p-margin-bottom'
                      dangerouslySetInnerHTML={{
                        __html: a.acf.description
                      }} />

                  </div>

                </div>
                <div className='border-b border-gray-400'>
                  <h1 className='text-black text-2xl lg:text-5xl uppercase'>Key<span className='font-black'> Tracks</span></h1>
                </div>
                <div>
                  {a.songs.map((song, j) => {
                    let showPause = false

                    if (song === this.props.data.selectedSong && this.props.data.songPlaying) {
                      showPause = true
                    }
                    if (j < 5 || this.state.showAllSongs) {
                      return (
                        <button key={song.ID} onClick={() => { dispatch({ type: 'SET_SONG', 'data': song, 'songPlaying': !showPause }) }} className='focus:outline-none bg-white rounded w-full overflow-hidden flex flex-row items-center justify-start p-2 mt-4 transition duration-500 ease-in-out hover:shadow-xl group'>

                          <div className='w-12 h-12 bg-orange-900 flex items-center justify-center text-white'>
                            {!showPause &&
                              <div className='relative'>
                                <FontAwesomeIcon className='transition duration-500 ease-in-out opacity-0 group-hover:opacity-100 absolute mx-auto inset-0 h-full' icon={faPlay} />
                                <S3Image className={'transition duration-500 ease-in-out w-full opacity-100 group-hover:opacity-0'} width={100} height={100} fit={'cover'} image={song.acf.image} />
                              </div>
                            }
                            {showPause &&
                              <FontAwesomeIcon icon={faPause} />
                            }
                          </div>
                          <div className='text-sm pl-4 font-bold'>{song.post_title}</div>
                          <div className='text-xs pr-4 text-gray-500' />
                        </button>
                      )
                    }
                    return null
                  })}
                  {a.songs.length > 5 &&
                    <button className='focus:outline-none text-orange-900 underline text-xs mt-2' onClick={() => { this.setState({ showAllSongs: !this.state.showAllSongs }) }}>Show {this.state.showAllSongs ? 'fewer' : 'more'} tracks</button>
                  }
                </div>
                {/* {a.albums && a.albums.length &&
                  <div>
                    <div className='border-b border-gray-400 mt-24'>
                      <h1 className='text-black font-black text-6xl uppercase'>Albums</h1>
                    </div>
                    <div className='flex flex-row flex-wrap -mx-8 mt-12'>

                      {a.albums.map(b => (
                        <div className='w-1/3 px-8'>
                          <button onClick={() => { this.setState({ album: b.ID }) }} className='bg-white p-12 focus:outline-none rounded hover:shadow-xl transition duration-500 ease-in-out'>
                            <S3Image className={`w-full`} width={200} height={200} fit={'cover'} image={b.acf.artwork} />
                            <p className='text-center text-sm pt-4 font-semibold'>{b['post_title']}</p>
                            <p className='text-center text-xs text-gray-500'>{b.albumSongs.length} tracks</p>

                          </button>
                        </div>

                      ))}
                    </div>

                  </div>
                } */}

                {
                  album &&
                  <div>

                    {album.albumSongs.map(s => {
                      let song = a.songs.find(p => p.ID === s)
                      let showPause = false

                      if (song === this.props.data.selectedSong && this.props.data.songPlaying) {
                        showPause = true
                      }
                      return (
                        <button key={song.ID} onClick={() => { dispatch({ type: 'SET_SONG', 'data': song, 'songPlaying': !showPause }) }} className='focus:outline-none bg-white rounded w-full overflow-hidden flex flex-row items-center justify-start p-2 mt-4 transition duration-500 ease-in-out hover:shadow-xl group'>

                          <div className='w-12 h-12 bg-orange-900 flex items-center justify-center text-white'>
                            {!showPause &&
                            <div className='relative'>
                              <FontAwesomeIcon className='transition duration-500 ease-in-out opacity-0 group-hover:opacity-100 absolute mx-auto inset-0 h-full' icon={faPlay} />
                              <S3Image className={'transition duration-500 ease-in-out w-full opacity-100 group-hover:opacity-0'} width={100} height={100} fit={'cover'} image={song.acf.image} />
                            </div>
                            }
                            {showPause &&
                            <FontAwesomeIcon icon={faPause} />
                            }
                          </div>
                          <div className='text-sm pl-4 font-bold'>{song.post_title}</div>
                          <div className='text-xs pr-4 text-gray-500' />
                        </button>
                      )
                    })}
                  </div>
                }

              </div>

            }

          </div>

        </div>

      </div>

    )
  }
};

const mapStateToProps = ({ data }) => ({
  data
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatch: o => o
    },
    dispatch)

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile))
