const initialState = {
  artists: null,
  pages: null,
  showModal: false,
  selectedArtist: null,
  selectedSong: null,
  songPlaying: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ARTISTS':
      return {
        ...state,
        artists: action.data
      }
    case 'GET_PAGES':
      return {
        ...state,
        pages: action.data
      }
    case 'TOGGLE_MODAL':
      return {
        ...state,
        showModal: action.data,
        selectedArtist: action.artist ? action.artist : null
      }
    case 'SET_ARTIST':
      return {
        ...state,
        selectedArtist: action.data
      }
    case 'SET_SONG':
      return {
        ...state,
        selectedSong: action.data,
        songPlaying: action.songPlaying
      }
    case 'SONG_PLAYING':
      return {
        ...state,
        songPlaying: action.data
      }

    default:
      return state
  }
}
